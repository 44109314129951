import React from 'react'

const Footer = () => {
  return (
    <footer class="attribution">
    Challenge by <a href="https://www.frontendmentor.io?ref=challenge" target="_blank">Frontend Mentor</a>. 
    Coded by <a href="#">Muhammad Faraz</a>.
  </footer>
  )
}

export default Footer